<template>
    <div class="hello">
      
      <svg class = "landing_box"  version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
             viewBox="0 0 1920 1080" style="enable-background:new 0 0 1920 1080;" xml:space="preserve">
          <g class = "back-gradient" id="Layer_1_00000011003205384915272820000014475396305426933160_">
          </g>
          <g class = "back-gradient" id="Layer_2_00000011015876966253674030000007538755937293247892_">
            <g class = "back-gradient">
              <g class = "back-gradient">
                <defs>
                  <rect id="SVGID_00000093165162808655893170000002171034899660545722_" x="230" y="259" width="1212" height="501"/>
                </defs>
                <clipPath id="SVGID_00000177460901860206490350000009304261543205931665_">
                  <use xlink:href="#SVGID_00000093165162808655893170000002171034899660545722_"  style="overflow:visible;"/>
                </clipPath>
                <g class = "back-gradient" style="clip-path:url(#SVGID_00000177460901860206490350000009304261543205931665_);">
                  <g class = "back-gradient">
                    <defs>
                      <rect id="SVGID_00000114054718103206882230000011441839282442487730_" x="230" y="259" width="1212" height="501"/>
                    </defs>
                    <clipPath id="SVGID_00000049200763451484325270000004061661146286819214_">
                      <use xlink:href="#SVGID_00000114054718103206882230000011441839282442487730_"  style="overflow:visible;"/>
                    </clipPath>
                    <g class = "back-gradient" style="clip-path:url(#SVGID_00000049200763451484325270000004061661146286819214_);">
                      <g class = "back-gradient">
                        <path d="M1305.5,624V396h-949v228H1305.5z M208.5,772V248h1245v524H208.5z"/>
                        <path class="st5" d="M1305.8,624.3V395.7H356.2v228.5L1305.8,624.3L1305.8,624.3z M208.8,771.7V248.3h1244.5v523.5
                          L208.8,771.7L208.8,771.7z"/>
                        <path class="st6" d="M1306,624.5v-229H356v229.1L1306,624.5L1306,624.5z M209,771.5v-523h1244v522.9L209,771.5L209,771.5z"/>
                        <path class="st7" d="M1306.3,624.8V395.2H355.7v229.6L1306.3,624.8L1306.3,624.8z M209.3,771.2V248.8h1243.4v522.4H209.3z"/>
                        <path class="st8" d="M1306.6,625.1V394.9H355.4v230.2H1306.6z M209.6,770.9V249.1h1242.8v521.8H209.6z"/>
                        <path class="st9" d="M1306.9,625.4V394.6H355.1v230.7h951.8V625.4z M209.9,770.6V249.4h1242.3v521.3L209.9,770.6L209.9,770.6
                          z"/>
                        <path class="st10" d="M1307.1,625.6V394.4H354.9v231.3h952.2V625.6z M210.1,770.4V249.6h1241.7v520.7L210.1,770.4
                          L210.1,770.4z"/>
                        <path class="st11" d="M1307.4,625.9V394.1H354.6v231.8H1307.4z M210.4,770.1V249.9h1241.2v520.2H210.4z"/>
                        <path class="st12" d="M1307.7,626.2V393.8H354.3v232.4L1307.7,626.2L1307.7,626.2z M210.7,769.8V250.2h1240.6v519.6H210.7z"
                          />
                        <path class="st13" d="M1308,626.5v-233H354v232.9L1308,626.5L1308,626.5z M211,769.5v-519h1240v519.1L211,769.5L211,769.5z"
                          />
                        <path class="st14" d="M1308.2,626.7V393.3H353.8v233.5L1308.2,626.7L1308.2,626.7z M211.2,769.3V250.7h1239.5v518.5
                          L211.2,769.3L211.2,769.3z"/>
                        <path class="st15" d="M1308.5,627V393h-955v234H1308.5z M211.5,769V251h1239v518H211.5z"/>
                        <path class="st16" d="M1308.8,627.3V392.7H353.2v234.6L1308.8,627.3L1308.8,627.3z M211.8,768.7V251.3h1238.4v517.4H211.8z"
                          />
                        <path class="st17" d="M1309.1,627.6V392.4H352.9v235.1L1309.1,627.6L1309.1,627.6z M212.1,768.4V251.6H1450v516.9
                          L212.1,768.4L212.1,768.4z"/>
                        <path class="st18" d="M1309.3,627.8V392.2H352.7v235.7h956.6V627.8z M212.3,768.2V251.8h1237.3v516.3H212.3V768.2z"/>
                        <path class="st19" d="M1309.6,628.1V391.9H352.4v236.2H1309.6z M212.6,767.9V252.1h1236.8v515.8H212.6z"/>
                        <path class="st20" d="M1309.9,628.4V391.6H352.1v236.8H1309.9z M212.9,767.6V252.4h1236.2v515.2H212.9z"/>
                        <path class="st21" d="M1310.2,628.7V391.3H351.8v237.3h958.4V628.7z M213.2,767.3V252.7h1235.7v514.7H213.2V767.3z"/>
                        <path class="st22" d="M1310.4,628.9V391.1H351.6V629L1310.4,628.9L1310.4,628.9z M213.4,767.1V252.9h1235.1V767L213.4,767.1
                          L213.4,767.1z"/>
                        <path class="st23" d="M1310.7,629.2V390.8H351.3v238.4L1310.7,629.2L1310.7,629.2z M213.7,766.8V253.2h1234.6v513.6H213.7z"
                          />
                        <path class="st24" d="M1311,629.5v-239H351v239H1311z M214,766.5v-513h1234v513H214z"/>
                        <path class="st25" d="M1311.3,629.8V390.2H350.7v239.5L1311.3,629.8L1311.3,629.8z M214.3,766.2V253.8h1233.5v512.5
                          L214.3,766.2L214.3,766.2z"/>
                        <path class="st26" d="M1311.5,630V390h-961v240H1311.5z M214.5,766V254h1232.9v512H214.5z"/>
                        <path class="st27" d="M1311.8,630.3V389.7H350.2v240.6L1311.8,630.3L1311.8,630.3z M214.8,765.7V254.3h1232.4v511.4
                          L214.8,765.7L214.8,765.7z"/>
                        <path class="st28" d="M1312.1,630.6V389.4H349.9v241.2H1312.1z M215.1,765.4V254.6h1231.8v510.8H215.1z"/>
                        <path class="st29" d="M1312.4,630.9V389.1H349.6v241.7h962.8V630.9z M215.4,765.1V254.9h1231.3v510.3L215.4,765.1
                          L215.4,765.1z"/>
                        <path class="st30" d="M1312.6,631.1V388.9H349.4v242.3h963.2V631.1z M215.6,764.9V255.1h1230.7v509.7L215.6,764.9
                          L215.6,764.9z"/>
                        <path class="st31" d="M1312.9,631.4V388.6H349.1v242.8H1312.9z M215.9,764.6V255.4h1230.2v509.2H215.9z"/>
                        <path class="st32" d="M1313.2,631.7V388.3H348.8v243.4L1313.2,631.7L1313.2,631.7z M216.2,764.3V255.7h1229.6v508.6
                          L216.2,764.3L216.2,764.3z"/>
                        <path class="st33" d="M1313.5,632V388h-965v244H1313.5z M216.5,764V256h1229.1v508H216.5z"/>
                        <path class="st34" d="M1313.7,632.2V387.8H348.3v244.5L1313.7,632.2L1313.7,632.2z M216.7,763.8V256.2h1228.5v507.5
                          L216.7,763.8L216.7,763.8z"/>
                        <path class="st35" d="M1314,632.5v-245H348v245H1314z M217,763.5v-507h1228v507H217z"/>
                        <path class="st36" d="M1314.3,632.8V387.2H347.7v245.6L1314.3,632.8L1314.3,632.8z M217.3,763.2V256.8h1227.4v506.4
                          L217.3,763.2L217.3,763.2z"/>
                        <path class="st37" d="M1314.6,633.1V386.9H347.4V633L1314.6,633.1L1314.6,633.1z M217.6,762.9V257.1h1226.9V763L217.6,762.9
                          L217.6,762.9z"/>
                        <path class="st38" d="M1314.8,633.3V386.7H347.2v246.7h967.6V633.3z M217.8,762.7V257.3h1226.3v505.3H217.8V762.7z"/>
                        <path class="st39" d="M1315.1,633.6V386.4H346.9v247.2H1315.1z M218.1,762.4V257.6h1225.8v504.8H218.1z"/>
                        <path class="st40" d="M1315.4,633.9V386.1H346.6v247.8H1315.4z M218.4,762.1V257.9h1225.2v504.2H218.4z"/>
                        <path class="st41" d="M1315.7,634.2V385.8H346.3v248.3h969.4V634.2z M218.7,761.8V258.2h1224.7v503.7H218.7V761.8z"/>
                        <path class="st42" d="M1315.9,634.4V385.6H346.1v248.9L1315.9,634.4L1315.9,634.4z M218.9,761.6V258.4H1443v503.1
                          L218.9,761.6L218.9,761.6z"/>
                        <path class="st43" d="M1316.2,634.7V385.3H345.8v249.4L1316.2,634.7L1316.2,634.7z M219.2,761.3V258.7h1223.6v502.6
                          L219.2,761.3L219.2,761.3z"/>
                        <path class="st44" d="M1316.5,635V385h-971v250H1316.5z M219.5,761V259h1223v502H219.5z"/>
                        <path class="st45" d="M1316.8,635.3V384.7H345.2v250.5L1316.8,635.3L1316.8,635.3z M219.8,760.7V259.3h1222.5v501.5
                          L219.8,760.7L219.8,760.7z"/>
                        <path class="st46" d="M1317,635.5v-251H345v251.1L1317,635.5L1317,635.5z M220,760.5v-501h1222v500.9L220,760.5L220,760.5z"
                          />
                        <path class="st47" d="M1317.3,635.8V384.2H344.7v251.6L1317.3,635.8L1317.3,635.8z M220.3,760.2V259.8h1221.4v500.4
                          L220.3,760.2L220.3,760.2z"/>
                        <path class="st48" d="M1317.6,636.1V383.9H344.4v252.2H1317.6z M220.6,759.9V260.1h1220.8v499.8H220.6z"/>
                        <path class="st49" d="M1317.9,636.4V383.6H344.1v252.7h973.8V636.4z M220.9,759.6V260.4h1220.3v499.3L220.9,759.6
                          L220.9,759.6z"/>
                        <path class="st50" d="M1318.1,636.6V383.4H343.9v253.3h974.2V636.6z M221.1,759.4V260.6h1219.7v498.7L221.1,759.4
                          L221.1,759.4z"/>
                        <path class="st51" d="M1318.4,636.9V383.1H343.6v253.8H1318.4z M221.4,759.1V260.9h1219.2v498.2H221.4z"/>
                        <path class="st52" d="M1318.7,637.2V382.8H343.3v254.4L1318.7,637.2L1318.7,637.2z M221.7,758.8V261.2h1218.6v497.6
                          L221.7,758.8L221.7,758.8z"/>
                        <path class="st53" d="M1319,637.5v-255H343v254.9L1319,637.5L1319,637.5z M222,758.5v-497h1218v497.1L222,758.5L222,758.5z"
                          />
                        <path class="st54" d="M1319.2,637.7V382.3H342.8v255.5L1319.2,637.7L1319.2,637.7z M222.2,758.3V261.7h1217.5v496.5
                          L222.2,758.3L222.2,758.3z"/>
                        <path class="st55" d="M1319.5,638V382h-977v256H1319.5z M222.5,758V262h1217v496H222.5z"/>
                        <path class="st56" d="M1319.8,638.3V381.7H342.2v256.5L1319.8,638.3L1319.8,638.3z M222.8,757.7V262.3h1216.5v495.5
                          L222.8,757.7L222.8,757.7z"/>
                        <path class="st57" d="M1320,638.5v-257H342v257.1L1320,638.5L1320,638.5z M223,757.5v-495h1216v494.9L223,757.5L223,757.5z"
                          />
                        <path class="st58" d="M1320.3,638.8V381.2H341.7v257.6L1320.3,638.8L1320.3,638.8z M223.3,757.2V262.8h1215.4v494.4
                          L223.3,757.2L223.3,757.2z"/>
                        <path class="st59" d="M1320.6,639.1V380.9H341.4v258.2H1320.6z M223.6,756.9V263.1h1214.8v493.8H223.6z"/>
                        <path class="st60" d="M1320.9,639.4V380.6H341.1v258.7L1320.9,639.4L1320.9,639.4z M223.9,756.6V263.4h1214.3v493.3
                          L223.9,756.6L223.9,756.6z"/>
                        <path class="st61" d="M1321.1,639.6V380.4H340.9v259.3L1321.1,639.6L1321.1,639.6z M224.1,756.4V263.6h1213.7v492.7
                          L224.1,756.4L224.1,756.4z"/>
                        <path class="st62" d="M1321.4,639.9V380.1H340.6v259.8H1321.4z M224.4,756.1V263.9h1213.2v492.2H224.4z"/>
                        <path class="st63" d="M1321.7,640.2V379.8H340.3v260.4L1321.7,640.2L1321.7,640.2z M224.7,755.8V264.2h1212.6v491.6
                          L224.7,755.8L224.7,755.8z"/>
                        <path class="st64" d="M1322,640.5v-261H340v260.9L1322,640.5L1322,640.5z M225,755.5v-491h1212v491.1L225,755.5L225,755.5z"
                          />
                        <path class="st65" d="M1322.2,640.7V379.3H339.8v261.5L1322.2,640.7L1322.2,640.7z M225.2,755.3V264.7h1211.5v490.5
                          L225.2,755.3L225.2,755.3z"/>
                        <path class="st66" d="M1322.5,641V379h-983v262H1322.5z M225.5,755V265h1211v490H225.5z"/>
                        <path class="st67" d="M1322.8,641.3V378.7H339.2v262.6L1322.8,641.3L1322.8,641.3z M225.8,754.7V265.3h1210.4v489.4
                          L225.8,754.7L225.8,754.7z"/>
                        <path class="st68" d="M1323.1,641.6V378.4H338.9v263.1L1323.1,641.6L1323.1,641.6z M226.1,754.4V265.6H1436v488.9
                          L226.1,754.4L226.1,754.4z"/>
                        <path class="st69" d="M1323.3,641.8V378.2H338.7v263.7h984.6V641.8z M226.3,754.2V265.8h1209.3v488.3H226.3V754.2z"/>
                        <path class="st70" d="M1323.6,642.1V377.9H338.4v264.2H1323.6z M226.6,753.9V266.1h1208.8v487.8H226.6z"/>
                        <path class="st71" d="M1323.9,642.4V377.6H338.1v264.8H1323.9z M226.9,753.6V266.4h1208.2v487.2H226.9z"/>
                        <path class="st72" d="M1324.2,642.7V377.3H337.8v265.3h986.4V642.7z M227.2,753.3V266.7h1207.7v486.7H227.2V753.3z"/>
                        <path class="st73" d="M1324.4,642.9V377.1H337.6V643L1324.4,642.9L1324.4,642.9z M227.4,753.1V266.9h1207.1V753L227.4,753.1
                          L227.4,753.1z"/>
                        <path class="st74" d="M1324.7,643.2V376.8H337.3v266.4L1324.7,643.2L1324.7,643.2z M227.7,752.8V267.2h1206.6v485.6
                          L227.7,752.8L227.7,752.8z"/>
                        <path class="st75" d="M1325,643.5v-267H337v267H1325z M228,752.5v-485h1206v485H228z"/>
                        <path class="st76" d="M1325.3,643.8V376.2H336.7v267.5L1325.3,643.8L1325.3,643.8z M228.3,752.2V267.8h1205.5v484.5
                          L228.3,752.2L228.3,752.2z"/>
                        <path class="st77" d="M1325.5,644V376h-989v268H1325.5z M228.5,752V268h1204.9v484H228.5z"/>
                        <path class="st78" d="M1325.8,644.3V375.7H336.2v268.6L1325.8,644.3L1325.8,644.3z M228.8,751.7V268.3h1204.4v483.4
                          L228.8,751.7L228.8,751.7z"/>
                        <path class="st79" d="M1326.1,644.6V375.4H335.9v269.2H1326.1z M229.1,751.4V268.6h1203.8v482.8H229.1z"/>
                        <path class="st80" d="M1326.4,644.9V375.1H335.6v269.7L1326.4,644.9L1326.4,644.9z M229.4,751.1V268.9h1203.3v482.3
                          L229.4,751.1L229.4,751.1z"/>
                        <path class="st81" d="M1326.6,645.1V374.9H335.4v270.3L1326.6,645.1L1326.6,645.1z M229.6,750.9V269.1h1202.7v481.7
                          L229.6,750.9L229.6,750.9z"/>
                        <path class="st82" d="M1326.9,645.4V374.6H335.1v270.8H1326.9z M229.9,750.6V269.4h1202.2v481.2H229.9z"/>
                        <path class="st83" d="M1327.2,645.7V374.3H334.8v271.4L1327.2,645.7L1327.2,645.7z M230.2,750.3V269.7h1201.6v480.6
                          L230.2,750.3L230.2,750.3z"/>
                        <path class="st84" d="M1327.5,646V374h-993v272H1327.5z M230.5,750V270h1201.1v480H230.5z"/>
                        <path class="st85" d="M1327.7,646.2V373.8H334.3v272.5L1327.7,646.2L1327.7,646.2z M230.7,749.8V270.2h1200.5v479.5
                          L230.7,749.8L230.7,749.8z"/>
                        <path class="st86" d="M1328,646.5v-273H334v273H1328z M231,749.5v-479h1200v479H231z"/>
                        <path class="st87" d="M1328.3,646.8V373.2H333.7v273.6L1328.3,646.8L1328.3,646.8z M231.3,749.2V270.8h1199.4v478.4
                          L231.3,749.2L231.3,749.2z"/>
                        <path class="st88" d="M1328.6,647.1V372.9H333.4V647L1328.6,647.1L1328.6,647.1z M231.6,748.9V271.1h1198.9V749L231.6,748.9
                          L231.6,748.9z"/>
                        <path class="st89" d="M1328.8,647.3V372.7H333.2v274.7h995.6V647.3z M231.8,748.7V271.3h1198.3v477.3H231.8V748.7z"/>
                        <path class="st90" d="M1329.1,647.6V372.4H332.9v275.2H1329.1z M232.1,748.4V271.6h1197.8v476.8H232.1z"/>
                        <path class="st91" d="M1329.4,647.9V372.1H332.6v275.8H1329.4z M232.4,748.1V271.9h1197.2v476.2H232.4z"/>
                        <path class="st92" d="M1329.7,648.2V371.8H332.3v276.3h997.4V648.2z M232.7,747.8V272.2h1196.7v475.7H232.7V747.8z"/>
                        <path class="st93" d="M1329.9,648.4V371.6H332.1v276.9L1329.9,648.4L1329.9,648.4z M232.9,747.6V272.4H1429v475.1
                          L232.9,747.6L232.9,747.6z"/>
                        <path class="st94" d="M1330.2,648.7V371.3H331.8v277.4L1330.2,648.7L1330.2,648.7z M233.2,747.3V272.7h1195.6v474.6
                          L233.2,747.3L233.2,747.3z"/>
                        <path class="st95" d="M1330.5,649V371h-999v278H1330.5z M233.5,747V273h1195v474H233.5z"/>
                        <path class="st96" d="M1330.8,649.3V370.7H331.2v278.5L1330.8,649.3L1330.8,649.3z M233.8,746.7V273.3h1194.5v473.5
                          L233.8,746.7L233.8,746.7z"/>
                        <path class="st97" d="M1331,649.5v-279H331v279.1L1331,649.5L1331,649.5z M234,746.5v-473h1194v472.9L234,746.5L234,746.5z"
                          />
                        <path class="st98" d="M1331.3,649.8V370.2H330.7v279.6L1331.3,649.8L1331.3,649.8z M234.3,746.2V273.8h1193.4v472.4
                          L234.3,746.2L234.3,746.2z"/>
                        <path class="st99" d="M1331.6,650.1V369.9H330.4v280.2H1331.6z M234.6,745.9V274.1h1192.8v471.8H234.6z"/>
                        <path class="st100" d="M1331.9,650.4V369.6H330.1v280.7L1331.9,650.4L1331.9,650.4z M234.9,745.6V274.4h1192.3v471.3
                          L234.9,745.6L234.9,745.6z"/>
                        <path class="st101" d="M1332.1,650.6V369.4H329.9v281.3L1332.1,650.6L1332.1,650.6z M235.1,745.4V274.6h1191.7v470.7
                          L235.1,745.4L235.1,745.4z"/>
                        <path class="st102" d="M1332.4,650.9V369.1H329.6v281.8H1332.4z M235.4,745.1V274.9h1191.2v470.2H235.4z"/>
                        <path class="st103" d="M1332.7,651.2V368.8H329.3v282.4L1332.7,651.2L1332.7,651.2z M235.7,744.8V275.2h1190.6v469.6
                          L235.7,744.8L235.7,744.8z"/>
                        <path class="st104" d="M1333,651.5v-283H329v282.9L1333,651.5L1333,651.5z M236,744.5v-469h1190v469.1L236,744.5L236,744.5z"
                          />
                        <path class="st105" d="M1333.2,651.7V368.3H328.8v283.5L1333.2,651.7L1333.2,651.7z M236.2,744.3V275.7h1189.5v468.5
                          L236.2,744.3L236.2,744.3z"/>
                        <path class="st106" d="M1333.5,652V368h-1005v284H1333.5z M236.5,744V276h1189v468H236.5z"/>
                        <path class="st107" d="M1333.8,652.3V367.7H328.2v284.5L1333.8,652.3L1333.8,652.3z M236.8,743.7V276.3h1188.5v467.5
                          L236.8,743.7L236.8,743.7z"/>
                        <path class="st108" d="M1334,652.5v-285H328v285.1L1334,652.5L1334,652.5z M237,743.5v-467h1188v466.9L237,743.5L237,743.5z"
                          />
                        <path class="st109" d="M1334.3,652.8V367.2H327.7v285.6L1334.3,652.8L1334.3,652.8z M237.3,743.2V276.8h1187.4v466.4
                          L237.3,743.2L237.3,743.2z"/>
                        <path class="st110" d="M1334.6,653.1V366.9H327.4v286.2H1334.6z M237.6,742.9V277.1h1186.8v465.8H237.6z"/>
                        <path class="st111" d="M1334.9,653.4V366.6H327.1v286.7L1334.9,653.4L1334.9,653.4z M237.9,742.6V277.4h1186.3v465.3
                          L237.9,742.6L237.9,742.6z"/>
                        <path class="st112" d="M1335.1,653.6V366.4H326.9v287.3L1335.1,653.6L1335.1,653.6z M238.1,742.4V277.6h1185.7v464.7
                          L238.1,742.4L238.1,742.4z"/>
                        <path class="st113" d="M1335.4,653.9V366.1H326.6v287.8H1335.4z M238.4,742.1V277.9h1185.2v464.2H238.4z"/>
                        <path class="st114" d="M1335.7,654.2V365.8H326.3v288.4L1335.7,654.2L1335.7,654.2z M238.7,741.8V278.2h1184.6v463.6
                          L238.7,741.8L238.7,741.8z"/>
                        <path class="st115" d="M1336,654.5v-289H326v288.9L1336,654.5L1336,654.5z M239,741.5v-463h1184v463.1L239,741.5L239,741.5z"
                          />
                        <path class="st116" d="M1336.2,654.7V365.3H325.8v289.5L1336.2,654.7L1336.2,654.7z M239.2,741.3V278.7h1183.5v462.5
                          L239.2,741.3L239.2,741.3z"/>
                        <path class="st117" d="M1336.5,655V365h-1011v290H1336.5z M239.5,741V279h1183v462H239.5z"/>
                        <path class="st118" d="M1336.8,655.3V364.7H325.2v290.6L1336.8,655.3L1336.8,655.3z M239.8,740.7V279.3h1182.4v461.4
                          L239.8,740.7L239.8,740.7z"/>
                        <path class="st119" d="M1337.1,655.6V364.4H324.9v291.1L1337.1,655.6L1337.1,655.6z M240.1,740.4V279.6H1422v460.9
                          L240.1,740.4L240.1,740.4z"/>
                        <path class="st120" d="M1337.3,655.8V364.2H324.7v291.7h1012.6V655.8z M240.3,740.2V279.8h1181.3v460.3H240.3V740.2z"/>
                        <path class="st121" d="M1337.6,656.1V363.9H324.4v292.2H1337.6z M240.6,739.9V280.1h1180.8v459.8H240.6z"/>
                        <path class="st122" d="M1337.9,656.4V363.6H324.1v292.8H1337.9z M240.9,739.6V280.4h1180.2v459.2H240.9z"/>
                        <path class="st123" d="M1338.2,656.7V363.3H323.8v293.3h1014.4V656.7z M241.2,739.3V280.7h1179.7v458.7H241.2V739.3z"/>
                        <path class="st124" d="M1338.4,656.9V363.1H323.6V657L1338.4,656.9L1338.4,656.9z M241.4,739.1V280.9h1179.1V739L241.4,739.1
                          L241.4,739.1z"/>
                        <path class="st125" d="M1338.7,657.2V362.8H323.3v294.4L1338.7,657.2L1338.7,657.2z M241.7,738.8V281.2h1178.6v457.6
                          L241.7,738.8L241.7,738.8z"/>
                        <path class="st126" d="M1339,657.5v-295H323v295H1339z M242,738.5v-457h1178v457H242z"/>
                        <path class="st127" d="M1339.3,657.8V362.2H322.7v295.5L1339.3,657.8L1339.3,657.8z M242.3,738.2V281.8h1177.5v456.5
                          L242.3,738.2L242.3,738.2z"/>
                        <path class="st128" d="M1339.5,658V362h-1017v296H1339.5z M242.5,738V282h1176.9v456H242.5z"/>
                        <path class="st129" d="M1339.8,658.3V361.7H322.2v296.6L1339.8,658.3L1339.8,658.3z M242.8,737.7V282.3h1176.4v455.4
                          L242.8,737.7L242.8,737.7z"/>
                        <path class="st130" d="M1340.1,658.6V361.4H321.9v297.2H1340.1z M243.1,737.4V282.6h1175.8v454.8H243.1z"/>
                        <path class="st131" d="M1340.4,658.9V361.1H321.6v297.7L1340.4,658.9L1340.4,658.9z M243.4,737.1V282.9h1175.3v454.3
                          L243.4,737.1L243.4,737.1z"/>
                        <path class="st132" d="M1340.6,659.1V360.9H321.4v298.3L1340.6,659.1L1340.6,659.1z M243.6,736.9V283.1h1174.7v453.7
                          L243.6,736.9L243.6,736.9z"/>
                        <path class="st133" d="M1340.9,659.4V360.6H321.1v298.8H1340.9z M243.9,736.6V283.4h1174.2v453.2H243.9z"/>
                        <path class="st134" d="M1341.2,659.7V360.3H320.8v299.4L1341.2,659.7L1341.2,659.7z M244.2,736.3V283.7h1173.6v452.6
                          L244.2,736.3L244.2,736.3z"/>
                        <path class="st135" d="M1341.5,660V360h-1021v300H1341.5z M244.5,736V284h1173.1v452H244.5z"/>
                        <path class="st136" d="M1341.7,660.2V359.8H320.3v300.5L1341.7,660.2L1341.7,660.2z M244.7,735.8V284.2h1172.5v451.5
                          L244.7,735.8L244.7,735.8z"/>
                        <path class="st137" d="M1342,660.5v-301H320v301H1342z M245,735.5v-451h1172v451H245z"/>
                        <path class="st138" d="M1342.3,660.8V359.2H319.7v301.6L1342.3,660.8L1342.3,660.8z M245.3,735.2V284.8h1171.4v450.4
                          L245.3,735.2L245.3,735.2z"/>
                        <path class="st139" d="M1342.6,661.1V358.9H319.4V661L1342.6,661.1L1342.6,661.1z M245.6,734.9V285.1h1170.9V735L245.6,734.9
                          L245.6,734.9z"/>
                        <path class="st140" d="M1342.8,661.3V358.7H319.2v302.7h1023.6V661.3z M245.8,734.7V285.3h1170.3v449.3H245.8V734.7z"/>
                        <path class="st141" d="M1343.1,661.6V358.4H318.9v303.2H1343.1z M246.1,734.4V285.6h1169.8v448.8H246.1z"/>
                        <path class="st142" d="M1343.4,661.9V358.1H318.6v303.8H1343.4z M246.4,734.1V285.9h1169.2v448.2H246.4z"/>
                        <path class="st143" d="M1343.7,662.2V357.8H318.3v304.3h1025.4V662.2z M246.7,733.8V286.2h1168.7v447.7H246.7V733.8z"/>
                        <path class="st144" d="M1343.9,662.4V357.6H318.1v304.9L1343.9,662.4L1343.9,662.4z M246.9,733.6V286.4H1415v447.1
                          L246.9,733.6L246.9,733.6z"/>
                        <path class="st145" d="M1344.2,662.7V357.3H317.8v305.4L1344.2,662.7L1344.2,662.7z M247.2,733.3V286.7h1167.6v446.6
                          L247.2,733.3L247.2,733.3z"/>
                        <path class="st146" d="M1344.5,663V357h-1027v306H1344.5z M247.5,733V287h1167v446H247.5z"/>
                        <path class="st147" d="M1344.8,663.3V356.7H317.2v306.5L1344.8,663.3L1344.8,663.3z M247.8,732.7V287.3h1166.5v445.5
                          L247.8,732.7L247.8,732.7z"/>
                        <path class="st148" d="M1345,663.5v-307H317v307.1L1345,663.5L1345,663.5z M248,732.5v-445h1166v444.9L248,732.5L248,732.5z"
                          />
                        <path class="st149" d="M1345.3,663.8V356.2H316.7v307.6L1345.3,663.8L1345.3,663.8z M248.3,732.2V287.8h1165.4v444.4
                          L248.3,732.2L248.3,732.2z"/>
                        <path class="st150" d="M1345.6,664.1V355.9H316.4v308.2H1345.6z M248.6,731.9V288.1h1164.8v443.8H248.6z"/>
                        <path class="st151" d="M1345.9,664.4V355.6H316.1v308.7L1345.9,664.4L1345.9,664.4z M248.9,731.6V288.4h1164.3v443.3
                          L248.9,731.6L248.9,731.6z"/>
                        <path class="st152" d="M1346.1,664.6V355.4H315.9v309.3L1346.1,664.6L1346.1,664.6z M249.1,731.4V288.6h1163.7v442.7
                          L249.1,731.4L249.1,731.4z"/>
                        <path class="st153" d="M1346.4,664.9V355.1H315.6v309.8H1346.4z M249.4,731.1V288.9h1163.2v442.2H249.4z"/>
                        <path class="st154" d="M1346.7,665.2V354.8H315.3v310.4L1346.7,665.2L1346.7,665.2z M249.7,730.8V289.2h1162.6v441.6
                          L249.7,730.8L249.7,730.8z"/>
                        <path class="st155" d="M1347,665.5v-311H315v310.9L1347,665.5L1347,665.5z M250,730.5v-441h1162v441.1L250,730.5L250,730.5z"
                          />
                        <path class="st156" d="M1347.2,665.7V354.3H314.8v311.5L1347.2,665.7L1347.2,665.7z M250.2,730.3V289.7h1161.5v440.5
                          L250.2,730.3L250.2,730.3z"/>
                        <path class="st157" d="M1347.5,666V354h-1033v312H1347.5z M250.5,730V290h1161v440H250.5z"/>
                        <path class="st158" d="M1347.8,666.3V353.7H314.2v312.5L1347.8,666.3L1347.8,666.3z M250.8,729.7V290.3h1160.5v439.5
                          L250.8,729.7L250.8,729.7z"/>
                        <path class="st159" d="M1348,666.5v-313H314v313.1L1348,666.5L1348,666.5z M251,729.5v-439h1160v438.9L251,729.5L251,729.5z"
                          />
                        <path class="st160" d="M1348.3,666.8V353.2H313.7v313.6L1348.3,666.8L1348.3,666.8z M251.3,729.2V290.8h1159.4v438.4
                          L251.3,729.2L251.3,729.2z"/>
                        <path class="st161" d="M1348.6,667.1V352.9H313.4v314.2H1348.6z M251.6,728.9V291.1h1158.8v437.8H251.6z"/>
                        <path class="st162" d="M1348.9,667.4V352.6H313.1v314.7L1348.9,667.4L1348.9,667.4z M251.9,728.6V291.4h1158.3v437.3
                          L251.9,728.6L251.9,728.6z"/>
                        <path class="st163" d="M1349.1,667.6V352.4H312.9v315.3L1349.1,667.6L1349.1,667.6z M252.1,728.4V291.6h1157.7v436.7
                          L252.1,728.4L252.1,728.4z"/>
                        <path class="st164" d="M1349.4,667.9V352.1H312.6v315.8H1349.4z M252.4,728.1V291.9h1157.2v436.2H252.4z"/>
                        <path class="st165" d="M1349.7,668.2V351.8H312.3v316.4L1349.7,668.2L1349.7,668.2z M252.7,727.8V292.2h1156.6v435.6
                          L252.7,727.8L252.7,727.8z"/>
                        <path class="st166" d="M1350,668.5v-317H312v316.9L1350,668.5L1350,668.5z M253,727.5v-435h1156v435.1L253,727.5L253,727.5z"
                          />
                        <path class="st167" d="M1350.2,668.7V351.3H311.8v317.5L1350.2,668.7L1350.2,668.7z M253.2,727.3V292.7h1155.5v434.5
                          L253.2,727.3L253.2,727.3z"/>
                        <path class="st168" d="M1350.5,669V351h-1039v318H1350.5z M253.5,727V293h1155v434H253.5z"/>
                        <path class="st169" d="M1350.8,669.3V350.7H311.2v318.6L1350.8,669.3L1350.8,669.3z M253.8,726.7V293.3h1154.4v433.4
                          L253.8,726.7L253.8,726.7z"/>
                        <path class="st170" d="M1351.1,669.6V350.4H310.9v319.1L1351.1,669.6L1351.1,669.6z M254.1,726.4V293.6H1408v432.9
                          L254.1,726.4L254.1,726.4z"/>
                        <path class="st171" d="M1351.3,669.8V350.2H310.7v319.7h1040.6V669.8z M254.3,726.2V293.8h1153.3v432.3H254.3V726.2z"/>
                        <path class="st172" d="M1351.6,670.1V349.9H310.4v320.2H1351.6z M254.6,725.9V294.1h1152.8v431.8H254.6z"/>
                        <path class="st173" d="M1351.9,670.4V349.6H310.1v320.8H1351.9z M254.9,725.6V294.4h1152.2v431.2H254.9z"/>
                        <path class="st174" d="M1352.2,670.7V349.3H309.8v321.3h1042.4V670.7z M255.2,725.3V294.7h1151.7v430.7H255.2V725.3z"/>
                        <path class="st175" d="M1352.4,670.9V349.1H309.6V671L1352.4,670.9L1352.4,670.9z M255.4,725.1V294.9h1151.1V725L255.4,725.1
                          L255.4,725.1z"/>
                        <path class="st176" d="M1352.7,671.2V348.8H309.3v322.4L1352.7,671.2L1352.7,671.2z M255.7,724.8V295.2h1150.6v429.6
                          L255.7,724.8L255.7,724.8z"/>
                        <path class="st177" d="M1353,671.5v-323H309v323H1353z M256,724.5v-429h1150v429H256z"/>
                        <path class="st178" d="M1353.3,671.8V348.2H308.7v323.5L1353.3,671.8L1353.3,671.8z M256.3,724.2V295.8h1149.5v428.5
                          L256.3,724.2L256.3,724.2z"/>
                        <path class="st179" d="M1353.5,672V348h-1045v324H1353.5z M256.5,724V296h1148.9v428H256.5z"/>
                        <path class="st180" d="M1353.8,672.3V347.7H308.2v324.6L1353.8,672.3L1353.8,672.3z M256.8,723.7V296.3h1148.4v427.4
                          L256.8,723.7L256.8,723.7z"/>
                        <path class="st181" d="M1354.1,672.6V347.4H307.9v325.2H1354.1z M257.1,723.4V296.6h1147.8v426.8H257.1z"/>
                        <path class="st182" d="M1354.4,672.9V347.1H307.6v325.7L1354.4,672.9L1354.4,672.9z M257.4,723.1V296.9h1147.3v426.3
                          L257.4,723.1L257.4,723.1z"/>
                        <path class="st183" d="M1354.6,673.1V346.9H307.4v326.3L1354.6,673.1L1354.6,673.1z M257.6,722.9V297.1h1146.7v425.7
                          L257.6,722.9L257.6,722.9z"/>
                        <path class="st184" d="M1354.9,673.4V346.6H307.1v326.8H1354.9z M257.9,722.6V297.4h1146.2v425.2H257.9z"/>
                        <path class="st185" d="M1355.2,673.7V346.3H306.8v327.4L1355.2,673.7L1355.2,673.7z M258.2,722.3V297.7h1145.6v424.6
                          L258.2,722.3L258.2,722.3z"/>
                        <path class="st186" d="M1355.5,674V346h-1049v328H1355.5z M258.5,722V298h1145.1v424H258.5z"/>
                        <path class="st187" d="M1355.7,674.2V345.8H306.3v328.5L1355.7,674.2L1355.7,674.2z M258.7,721.8V298.2h1144.5v423.5
                          L258.7,721.8L258.7,721.8z"/>
                        <path class="st188" d="M1356,674.5v-329H306v329H1356z M259,721.5v-423h1144v423H259z"/>
                        <path class="st189" d="M1356.3,674.8V345.2H305.7v329.6L1356.3,674.8L1356.3,674.8z M259.3,721.2V298.8h1143.4v422.4
                          L259.3,721.2L259.3,721.2z"/>
                        <path class="st190" d="M1356.6,675.1V344.9H305.4V675L1356.6,675.1L1356.6,675.1z M259.6,720.9V299.1h1142.9V721L259.6,720.9
                          L259.6,720.9z"/>
                        <path class="st191" d="M1356.8,675.3V344.7H305.2v330.7h1051.6V675.3z M259.8,720.7V299.3h1142.3v421.3H259.8V720.7z"/>
                        <path class="st192" d="M1357.1,675.6V344.4H304.9v331.2H1357.1z M260.1,720.4V299.6h1141.8v420.8H260.1z"/>
                        <path class="st193" d="M1357.4,675.9V344.1H304.6v331.8H1357.4z M260.4,720.1V299.9h1141.2v420.2H260.4z"/>
                        <path class="st194" d="M1357.7,676.2V343.8H304.3v332.3h1053.4V676.2z M260.7,719.8V300.2h1140.7v419.7H260.7V719.8z"/>
                        <path class="st195" d="M1357.9,676.4V343.6H304.1v332.9L1357.9,676.4L1357.9,676.4z M260.9,719.6V300.4H1401v419.1
                          L260.9,719.6L260.9,719.6z"/>
                        <path class="st196" d="M1358.2,676.7V343.3H303.8v333.4L1358.2,676.7L1358.2,676.7z M261.2,719.3V300.7h1139.6v418.6
                          L261.2,719.3L261.2,719.3z"/>
                        <path class="st197" d="M1358.5,677V343h-1055v334H1358.5z M261.5,719V301h1139v418H261.5z"/>
                        <path class="st198" d="M1358.8,677.3V342.7H303.2v334.5L1358.8,677.3L1358.8,677.3z M261.8,718.7V301.3h1138.5v417.5
                          L261.8,718.7L261.8,718.7z"/>
                        <path class="st199" d="M1359,677.5v-335H303v335.1L1359,677.5L1359,677.5z M262,718.5v-417h1138v416.9L262,718.5L262,718.5z"
                          />
                        <path class="st200" d="M1359.3,677.8V342.2H302.7v335.6L1359.3,677.8L1359.3,677.8z M262.3,718.2V301.8h1137.4v416.4
                          L262.3,718.2L262.3,718.2z"/>
                        <path class="st201" d="M1359.6,678.1V341.9H302.4v336.2H1359.6z M262.6,717.9V302.1h1136.8v415.8H262.6z"/>
                        <path class="st202" d="M1359.9,678.4V341.6H302.1v336.7L1359.9,678.4L1359.9,678.4z M262.9,717.6V302.4h1136.3v415.3
                          L262.9,717.6L262.9,717.6z"/>
                        <path class="st203" d="M1360.1,678.6V341.4H301.9v337.3L1360.1,678.6L1360.1,678.6z M263.1,717.4V302.6h1135.7v414.7
                          L263.1,717.4L263.1,717.4z"/>
                        <path class="st204" d="M1360.4,678.9V341.1H301.6v337.8H1360.4z M263.4,717.1V302.9h1135.2v414.2H263.4z"/>
                        <path class="st205" d="M1360.7,679.2V340.8H301.3v338.4L1360.7,679.2L1360.7,679.2z M263.7,716.8V303.2h1134.6v413.6
                          L263.7,716.8L263.7,716.8z"/>
                        <path class="st206" d="M1361,679.5v-339H301v338.9L1361,679.5L1361,679.5z M264,716.5v-413h1134v413.1L264,716.5L264,716.5z"
                          />
                        <path class="st207" d="M1361.2,679.7V340.3H300.8v339.5L1361.2,679.7L1361.2,679.7z M264.2,716.3V303.7h1133.5v412.5
                          L264.2,716.3L264.2,716.3z"/>
                        <path class="st208" d="M1361.5,680V340h-1061v340H1361.5z M264.5,716V304h1133v412H264.5z"/>
                        <path class="st209" d="M1361.8,680.3V339.7H300.2v340.5L1361.8,680.3L1361.8,680.3z M264.8,715.7V304.3h1132.5v411.5
                          L264.8,715.7L264.8,715.7z"/>
                        <path class="st210" d="M1362,680.5v-341H300v341.1L1362,680.5L1362,680.5z M265,715.5v-411h1132v410.9L265,715.5L265,715.5z"
                          />
                        <path class="st211" d="M1362.3,680.8V339.2H299.7v341.6L1362.3,680.8L1362.3,680.8z M265.3,715.2V304.8h1131.4v410.4
                          L265.3,715.2L265.3,715.2z"/>
                        <path class="st212" d="M1362.6,681.1V338.9H299.4v342.2H1362.6z M265.6,714.9V305.1h1130.8v409.8H265.6z"/>
                        <path class="st213" d="M1362.9,681.4V338.6H299.1v342.7L1362.9,681.4L1362.9,681.4z M265.9,714.6V305.4h1130.3v409.3
                          L265.9,714.6L265.9,714.6z"/>
                        <path class="st214" d="M1363.1,681.6V338.4H298.9v343.3L1363.1,681.6L1363.1,681.6z M266.1,714.4V305.6h1129.7v408.7
                          L266.1,714.4L266.1,714.4z"/>
                        <path class="st215" d="M1363.4,681.9V338.1H298.6v343.8H1363.4z M266.4,714.1V305.9h1129.2v408.2H266.4z"/>
                        <path class="st216" d="M1363.7,682.2V337.8H298.3v344.4L1363.7,682.2L1363.7,682.2z M266.7,713.8V306.2h1128.6v407.6
                          L266.7,713.8L266.7,713.8z"/>
                        <path class="st217" d="M1364,682.5v-345H298v344.9L1364,682.5L1364,682.5z M267,713.5v-407h1128v407.1L267,713.5L267,713.5z"
                          />
                        <path class="st218" d="M1364.2,682.7V337.3H297.8v345.5L1364.2,682.7L1364.2,682.7z M267.2,713.3V306.7h1127.5v406.5
                          L267.2,713.3L267.2,713.3z"/>
                        <path class="st219" d="M1364.5,683V337h-1067v346H1364.5z M267.5,713V307h1127v406H267.5z"/>
                        <path class="st220" d="M1364.8,683.3V336.7H297.2v346.6L1364.8,683.3L1364.8,683.3z M267.8,712.7V307.3h1126.4v405.4
                          L267.8,712.7L267.8,712.7z"/>
                        <path class="st221" d="M1365.1,683.6V336.4H296.9v347.1L1365.1,683.6L1365.1,683.6z M268.1,712.4V307.6H1394v404.9
                          L268.1,712.4L268.1,712.4z"/>
                        <path class="st222" d="M1365.3,683.8V336.2H296.7v347.7h1068.6V683.8z M268.3,712.2V307.8h1125.3v404.3H268.3V712.2z"/>
                        <path class="st223" d="M1365.6,684.1V335.9H296.4v348.2H1365.6z M268.6,711.9V308.1h1124.8v403.8H268.6z"/>
                        <path class="st224" d="M1365.9,684.4V335.6H296.1v348.8H1365.9z M268.9,711.6V308.4h1124.2v403.2H268.9z"/>
                        <path class="st225" d="M1366.2,684.7V335.3H295.8v349.3h1070.4V684.7z M269.2,711.3V308.7h1123.7v402.7H269.2V711.3z"/>
                        <path class="st226" d="M1366.4,684.9V335.1H295.6V685L1366.4,684.9L1366.4,684.9z M269.4,711.1V308.9h1123.1V711L269.4,711.1
                          L269.4,711.1z"/>
                        <path class="st227" d="M1366.7,685.2V334.8H295.3v350.4L1366.7,685.2L1366.7,685.2z M269.7,710.8V309.2h1122.6v401.6
                          L269.7,710.8L269.7,710.8z"/>
                        <path class="st228" d="M1367,685.5v-351H295v351H1367z M270,710.5v-401h1122v401H270z"/>
                        <path class="st229" d="M1367.3,685.8V334.2H294.7v351.5L1367.3,685.8L1367.3,685.8z M270.3,710.2V309.8h1121.5v400.5
                          L270.3,710.2L270.3,710.2z"/>
                        <path class="st230" d="M1367.5,686V334h-1073v352H1367.5z M270.5,710V310h1120.9v400H270.5z"/>
                        <path class="st231" d="M1367.8,686.3V333.7H294.2v352.6L1367.8,686.3L1367.8,686.3z M270.8,709.7V310.3h1120.4v399.4
                          L270.8,709.7L270.8,709.7z"/>
                        <path class="st232" d="M1368.1,686.6V333.4H293.9v353.2H1368.1z M271.1,709.4V310.6h1119.8v398.8H271.1z"/>
                        <path class="st233" d="M1368.4,686.9V333.1H293.6v353.7L1368.4,686.9L1368.4,686.9z M271.4,709.1V310.9h1119.3v398.3
                          L271.4,709.1L271.4,709.1z"/>
                        <path class="st234" d="M1368.6,687.1V332.9H293.4v354.3L1368.6,687.1L1368.6,687.1z M271.6,708.9V311.1h1118.7v397.7
                          L271.6,708.9L271.6,708.9z"/>
                        <path class="st235" d="M1368.9,687.4V332.6H293.1v354.8H1368.9z M271.9,708.6V311.4h1118.2v397.2H271.9z"/>
                        <path class="st236" d="M1369.2,687.7V332.3H292.8v355.4L1369.2,687.7L1369.2,687.7z M272.2,708.3V311.7h1117.6v396.6
                          L272.2,708.3L272.2,708.3z"/>
                        <path class="st237" d="M1369.5,688V332h-1077v356H1369.5z M272.5,708V312h1117.1v396H272.5z"/>
                        <path class="st238" d="M1369.7,688.2V331.8H292.3v356.5L1369.7,688.2L1369.7,688.2z M272.7,707.8V312.2h1116.5v395.5
                          L272.7,707.8L272.7,707.8z"/>
                        <path class="st239" d="M1370,688.5v-357H292v357H1370z M273,707.5v-395h1116v395H273z"/>
                        <path class="st240" d="M1370.3,688.8V331.2H291.7v357.6L1370.3,688.8L1370.3,688.8z M273.3,707.2V312.8h1115.4v394.4
                          L273.3,707.2L273.3,707.2z"/>
                        <path class="st241" d="M1370.6,689.1V330.9H291.4V689L1370.6,689.1L1370.6,689.1z M273.6,706.9V313.1h1114.9V707L273.6,706.9
                          L273.6,706.9z"/>
                        <path class="st242" d="M1370.8,689.3V330.7H291.2v358.7h1079.6V689.3z M273.8,706.7V313.3h1114.3v393.3H273.8V706.7z"/>
                        <path class="st243" d="M1371.1,689.6V330.4H290.9v359.2H1371.1z M274.1,706.4V313.6h1113.8v392.8H274.1z"/>
                        <path class="st244" d="M1371.4,689.9V330.1H290.6v359.8H1371.4z M274.4,706.1V313.9h1113.2v392.2H274.4z"/>
                        <path class="st245" d="M1371.7,690.2V329.8H290.3v360.3h1081.4V690.2z M274.7,705.8V314.2h1112.7v391.7H274.7V705.8z"/>
                        <path class="st246" d="M1371.9,690.4V329.6H290.1v360.9L1371.9,690.4L1371.9,690.4z M274.9,705.6V314.4H1387v391.1
                          L274.9,705.6L274.9,705.6z"/>
                        <path class="st247" d="M1372.2,690.7V329.3H289.8v361.4L1372.2,690.7L1372.2,690.7z M275.2,705.3V314.7h1111.6v390.6
                          L275.2,705.3L275.2,705.3z"/>
                        <path class="st248" d="M1372.5,691V329h-1083v362H1372.5z M275.5,705V315h1111v390H275.5z"/>
                        <path class="st249" d="M1372.8,691.3V328.7H289.2v362.5L1372.8,691.3L1372.8,691.3z M275.8,704.7V315.3h1110.5v389.5
                          L275.8,704.7L275.8,704.7z"/>
                        <path class="st250" d="M1373,691.5v-363H289v363.1L1373,691.5L1373,691.5z M276,704.5v-389h1110v388.9L276,704.5L276,704.5z"
                          />
                        <path class="st251" d="M1373.3,691.8V328.2H288.7v363.6L1373.3,691.8L1373.3,691.8z M276.3,704.2V315.8h1109.4v388.4
                          L276.3,704.2L276.3,704.2z"/>
                        <path class="st252" d="M1373.6,692.1V327.9H288.4v364.2H1373.6z M276.6,703.9V316.1h1108.8v387.8H276.6z"/>
                        <path class="st253" d="M1373.9,692.4V327.6H288.1v364.7L1373.9,692.4L1373.9,692.4z M276.9,703.6V316.4h1108.3v387.3
                          L276.9,703.6L276.9,703.6z"/>
                        <path class="st254" d="M1374.1,692.6V327.4H287.9v365.3L1374.1,692.6L1374.1,692.6z M277.1,703.4V316.6h1107.7v386.7
                          L277.1,703.4L277.1,703.4z"/>
                        <path class="st255" d="M1374.4,692.9V327.1H287.6v365.8H1374.4z M277.4,703.1V316.9h1107.2v386.2H277.4z"/>
                        <path class="st256" d="M1374.7,693.2V326.8H287.3v366.4L1374.7,693.2L1374.7,693.2z M277.7,702.8V317.2h1106.6v385.6
                          L277.7,702.8L277.7,702.8z"/>
                        <path class="st257" d="M1375,693.5v-367H287v366.9L1375,693.5L1375,693.5z M278,702.5v-385h1106v385.1L278,702.5L278,702.5z"
                          />
                        <path class="st258" d="M1375.2,693.7V326.3H286.8v367.5L1375.2,693.7L1375.2,693.7z M278.2,702.3V317.7h1105.5v384.5
                          L278.2,702.3L278.2,702.3z"/>
                        <path class="st259" d="M286.5,694h1089V326h-1089V694z M1383.5,702h-1105V318h1105V702z"/>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
          
          </svg>
          <router-link to="/about"><span></span>About Me!</router-link>
    </div>
  </template>
  
  <script>
  export default {
    name: 'HelloWorld',
    props: {
      msg: String
    }
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style lang = "scss" scoped>
  a
{
    position: absolute;
    top: 70%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 180px;
    height: 50px;
    background: #232323;
    text-decoration: none;
    text-transform: uppercase;
    text-align: center;
    line-height: 50px;
    color: #FF0;
    font-size: 20px;
    font-family: verdana;
    letter-spacing: 4px;
/*    border-radius: 20px; */
}

a:before,
a:after,
span:before,
span:after
{
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    background: #FF0;
    transition: 1s;
    mix-blend-mode: hue;
}

a:before
{
    top: -2px;
    left: -2px
}
a:after
{
    top: -2px;
    right: -2px;
}
span:before
{
    bottom: -2px;
    left: -2px
}
span:after
{
    bottom: -2px;
    right: -2px
}

a:hover:before,
a:hover:after,
a:hover span:before,
a:hover span:after
{
    width: calc( 180px/2 );
    height: calc( 50px/2 )
}
            .st0{display:none;}
            .st1{display:inline;}
            .st2{fill:url(#SVGID_1_);}
            .st3{clip-path:url(#SVGID_00000013910288081471735420000017164567983992042154_);}
            .st4{clip-path:url(#SVGID_00000142148556937534584250000016889103621132918185_);}
            .st5{fill:#010101;}
            .st6{fill:#020202;}
            .st7{fill:#030303;}
            .st8{fill:#040404;}
            .st9{fill:#050505;}
            .st10{fill:#060606;}
            .st11{fill:#070707;}
            .st12{fill:#080808;}
            .st13{fill:#090909;}
            .st14{fill:#0A0A0A;}
            .st15{fill:#0B0B0B;}
            .st16{fill:#0C0C0C;}
            .st17{fill:#0D0D0D;}
            .st18{fill:#0E0E0E;}
            .st19{fill:#0F0F0F;}
            .st20{fill:#101010;}
            .st21{fill:#111111;}
            .st22{fill:#121212;}
            .st23{fill:#131313;}
            .st24{fill:#141414;}
            .st25{fill:#151515;}
            .st26{fill:#161616;}
            .st27{fill:#171717;}
            .st28{fill:#181818;}
            .st29{fill:#191919;}
            .st30{fill:#1A1A1A;}
            .st31{fill:#1B1B1B;}
            .st32{fill:#1C1C1C;}
            .st33{fill:#1D1D1D;}
            .st34{fill:#1E1E1E;}
            .st35{fill:#1F1F1F;}
            .st36{fill:#202020;}
            .st37{fill:#212121;}
            .st38{fill:#222222;}
            .st39{fill:#232323;}
            .st40{fill:#242424;}
            .st41{fill:#252525;}
            .st42{fill:#262626;}
            .st43{fill:#272727;}
            .st44{fill:#282828;}
            .st45{fill:#292929;}
            .st46{fill:#2A2A2A;}
            .st47{fill:#2B2B2B;}
            .st48{fill:#2C2C2C;}
            .st49{fill:#2D2D2D;}
            .st50{fill:#2E2E2E;}
            .st51{fill:#2F2F2F;}
            .st52{fill:#303030;}
            .st53{fill:#313131;}
            .st54{fill:#323232;}
            .st55{fill:#333333;}
            .st56{fill:#343434;}
            .st57{fill:#353535;}
            .st58{fill:#363636;}
            .st59{fill:#373737;}
            .st60{fill:#383838;}
            .st61{fill:#393939;}
            .st62{fill:#3A3A3A;}
            .st63{fill:#3B3B3B;}
            .st64{fill:#3C3C3C;}
            .st65{fill:#3D3D3D;}
            .st66{fill:#3E3E3E;}
            .st67{fill:#3F3F3F;}
            .st68{fill:#404040;}
            .st69{fill:#414141;}
            .st70{fill:#424242;}
            .st71{fill:#434343;}
            .st72{fill:#444444;}
            .st73{fill:#454545;}
            .st74{fill:#464646;}
            .st75{fill:#474747;}
            .st76{fill:#484848;}
            .st77{fill:#494949;}
            .st78{fill:#4A4A4A;}
            .st79{fill:#4B4B4B;}
            .st80{fill:#4C4C4C;}
            .st81{fill:#4D4D4D;}
            .st82{fill:#4E4E4E;}
            .st83{fill:#4F4F4F;}
            .st84{fill:#505050;}
            .st85{fill:#515151;}
            .st86{fill:#525252;}
            .st87{fill:#535353;}
            .st88{fill:#545454;}
            .st89{fill:#555555;}
            .st90{fill:#565656;}
            .st91{fill:#575757;}
            .st92{fill:#585858;}
            .st93{fill:#595959;}
            .st94{fill:#5A5A5A;}
            .st95{fill:#5B5B5B;}
            .st96{fill:#5C5C5C;}
            .st97{fill:#5D5D5D;}
            .st98{fill:#5E5E5E;}
            .st99{fill:#5F5F5F;}
            .st100{fill:#606060;}
            .st101{fill:#616161;}
            .st102{fill:#626262;}
            .st103{fill:#636363;}
            .st104{fill:#646464;}
            .st105{fill:#656565;}
            .st106{fill:#666666;}
            .st107{fill:#676767;}
            .st108{fill:#686868;}
            .st109{fill:#696969;}
            .st110{fill:#6A6A6A;}
            .st111{fill:#6B6B6B;}
            .st112{fill:#6C6C6C;}
            .st113{fill:#6D6D6D;}
            .st114{fill:#6E6E6E;}
            .st115{fill:#6F6F6F;}
            .st116{fill:#707070;}
            .st117{fill:#717171;}
            .st118{fill:#727272;}
            .st119{fill:#737373;}
            .st120{fill:#747474;}
            .st121{fill:#757575;}
            .st122{fill:#767676;}
            .st123{fill:#777777;}
            .st124{fill:#787878;}
            .st125{fill:#797979;}
            .st126{fill:#7A7A7A;}
            .st127{fill:#7B7B7B;}
            .st128{fill:#7C7C7C;}
            .st129{fill:#7D7D7D;}
            .st130{fill:#7E7E7E;}
            .st131{fill:#7F7F7F;}
            .st132{fill:#808080;}
            .st133{fill:#818181;}
            .st134{fill:#828282;}
            .st135{fill:#838383;}
            .st136{fill:#848484;}
            .st137{fill:#858585;}
            .st138{fill:#868686;}
            .st139{fill:#878787;}
            .st140{fill:#888888;}
            .st141{fill:#898989;}
            .st142{fill:#8A8A8A;}
            .st143{fill:#8B8B8B;}
            .st144{fill:#8C8C8C;}
            .st145{fill:#8D8D8D;}
            .st146{fill:#8E8E8E;}
            .st147{fill:#8F8F8F;}
            .st148{fill:#909090;}
            .st149{fill:#919191;}
            .st150{fill:#929292;}
            .st151{fill:#939393;}
            .st152{fill:#949494;}
            .st153{fill:#959595;}
            .st154{fill:#969696;}
            .st155{fill:#979797;}
            .st156{fill:#989898;}
            .st157{fill:#999999;}
            .st158{fill:#9A9A9A;}
            .st159{fill:#9B9B9B;}
            .st160{fill:#9C9C9C;}
            .st161{fill:#9D9D9D;}
            .st162{fill:#9E9E9E;}
            .st163{fill:#9F9F9F;}
            .st164{fill:#A0A0A0;}
            .st165{fill:#A1A1A1;}
            .st166{fill:#A2A2A2;}
            .st167{fill:#A3A3A3;}
            .st168{fill:#A4A4A4;}
            .st169{fill:#A5A5A5;}
            .st170{fill:#A6A6A6;}
            .st171{fill:#A7A7A7;}
            .st172{fill:#A8A8A8;}
            .st173{fill:#A9A9A9;}
            .st174{fill:#AAAAAA;}
            .st175{fill:#ABABAB;}
            .st176{fill:#ACACAC;}
            .st177{fill:#ADADAD;}
            .st178{fill:#AEAEAE;}
            .st179{fill:#AFAFAF;}
            .st180{fill:#B0B0B0;}
            .st181{fill:#B1B1B1;}
            .st182{fill:#B2B2B2;}
            .st183{fill:#B3B3B3;}
            .st184{fill:#B4B4B4;}
            .st185{fill:#B5B5B5;}
            .st186{fill:#B6B6B6;}
            .st187{fill:#B7B7B7;}
            .st188{fill:#B8B8B8;}
            .st189{fill:#B9B9B9;}
            .st190{fill:#BABABA;}
            .st191{fill:#BBBBBB;}
            .st192{fill:#BCBCBC;}
            .st193{fill:#BDBDBD;}
            .st194{fill:#BEBEBE;}
            .st195{fill:#BFBFBF;}
            .st196{fill:#C0C0C0;}
            .st197{fill:#C1C1C1;}
            .st198{fill:#C2C2C2;}
            .st199{fill:#C3C3C3;}
            .st200{fill:#C4C4C4;}
            .st201{fill:#C5C5C5;}
            .st202{fill:#C6C6C6;}
            .st203{fill:#C7C7C7;}
            .st204{fill:#C8C8C8;}
            .st205{fill:#C9C9C9;}
            .st206{fill:#CACACA;}
            .st207{fill:#CBCBCB;}
            .st208{fill:#CCCCCC;}
            .st209{fill:#CDCDCD;}
            .st210{fill:#CECECE;}
            .st211{fill:#CFCFCF;}
            .st212{fill:#D0D0D0;}
            .st213{fill:#D1D1D1;}
            .st214{fill:#D2D2D2;}
            .st215{fill:#D3D3D3;}
            .st216{fill:#D4D4D4;}
            .st217{fill:#D5D5D5;}
            .st218{fill:#D6D6D6;}
            .st219{fill:#D7D7D7;}
            .st220{fill:#D8D8D8;}
            .st221{fill:#D9D9D9;}
            .st222{fill:#DADADA;}
            .st223{fill:#DBDBDB;}
            .st224{fill:#DCDCDC;}
            .st225{fill:#DDDDDD;}
            .st226{fill:#DEDEDE;}
            .st227{fill:#DFDFDF;}
            .st228{fill:#E0E0E0;}
            .st229{fill:#E1E1E1;}
            .st230{fill:#E2E2E2;}
            .st231{fill:#E3E3E3;}
            .st232{fill:#E4E4E4;}
            .st233{fill:#E5E5E5;}
            .st234{fill:#E6E6E6;}
            .st235{fill:#E7E7E7;}
            .st236{fill:#E8E8E8;}
            .st237{fill:#E9E9E9;}
            .st238{fill:#EAEAEA;}
            .st239{fill:#EBEBEB;}
            .st240{fill:#ECECEC;}
            .st241{fill:#EDEDED;}
            .st242{fill:#EEEEEE;}
            .st243{fill:#EFEFEF;}
            .st244{fill:#F0F0F0;}
            .st245{fill:#F1F1F1;}
            .st246{fill:#F2F2F2;}
            .st247{fill:#F3F3F3;}
            .st248{fill:#F4F4F4;}
            .st249{fill:#F5F5F5;}
            .st250{fill:#F6F6F6;}
            .st251{fill:#F7F7F7;}
            .st252{fill:#F8F8F8;}
            .st253{fill:#F9F9F9;}
            .st254{fill:#FAFAFA;}
            .st255{fill:#FBFBFB;}
            .st256{fill:#FCFCFC;}
            .st257{fill:#FDFDFD;}
            .st258{fill:#FEFEFE;}
            .st259{fill:#FFFFFF;}
  
  
  .hello {
    background: linear-gradient(90deg, rgba(19, 19, 2, 0.8), rgba(39, 24, 3, 0.8), rgba(49, 2, 2, 0.8));
    animation: gradient 20s ease infinite;
    background-size: 600% 600%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    
  }
  
  
  .landing_box {
    height: 100vh;
    position: relative;
    display: flex;
    justify-content: center;
    align-content: center;
    mix-blend-mode: color-dodge;
    padding-left: 200px;
    padding-bottom: 100px;
    opacity: 0.85;
  }
  .back-gradient {
    display: flex;
    align-self: center;
    justify-content: center;
    align-content: center;
  }
  
  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  @media only screen and (max-width: 1023px) {
  .landing_box {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-x: auto;
    max-width: 100%; 
    padding-left: 0;
  }
  svg {
    width: 100%; /* Set the width of the SVG container to 100% of its parent */
    height: auto; /* Automatically adjust the height based on the aspect ratio */
  }
  a {
    display: none
  }


}


  </style>
  